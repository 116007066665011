import RangeSlider from './range-slider'

class TimeSelector {

  constructor(element) {
    this.parentElement = document.querySelector(element)
    this.chartElement = this.parentElement.querySelector('.time-chart')
    this.sliderElement = this.parentElement.querySelector('.slider')
    this.fromElement = this.parentElement.querySelector('#date_from_input')
    this.toElement = this.parentElement.querySelector('#date_to_input')
    this.initializeSlider()
  }

  initializeSlider() {
    let newRangeSlider = new RangeSlider(this.sliderElement)
    newRangeSlider.onChange = function(min, max) {
      this.fromElement.value = min
      this.toElement.value = max
    }.bind(this)

    newRangeSlider.didChanged = function(min, max) {
      this.chartElement.data.datasets[0].backgroundColor = 'orange'
      this.chartElement.update(0)
    }.bind(this)
  }
}

export default TimeSelector

import Choices from 'choices.js'
import LeafletMap from './leaflet-map'

class LocationSelector {

  constructor(container) {
    this._element = document.querySelector(container)

    this._miniMapSelector = '.mini-map'

    this._maxMap = new LeafletMap('max-map')
    document.
      getElementById('mapModal').
      addEventListener('show.bs.modal', this.resetMap.bind(this))
  }

  handleMiniMapClick = (event) => {
    document.getElementById('mapModal').classList.toggle('show')

    this._maxMap.refreshMap()
  }

  resetMap() {
    this._maxMap.refreshMap()
  }
}

export default LocationSelector

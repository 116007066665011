import { DataSet } from 'vis-data/peer'
import { Network } from 'vis-network/peer'

document.addEventListener('turbo:load', function() {
  const data = JSON.parse(document.getElementById('concept-container').dataset.json)
  const nodesSet = new DataSet(data.nodes, {})
  const edgesSet = new DataSet(data.edges, {})

  // create a network
  const container = document.getElementById('concept-container');
  const options = {
    nodes: {
      shape: 'dot',
      size: 16,
    },
    physics: {
      forceAtlas2Based: {
        gravitationalConstant: -26,
        centralGravity: 0.005,
        springLength: 230,
        springConstant: 0.18,
      },
      maxVelocity: 146,
      solver: 'forceAtlas2Based',
      timestep: 0.35,
      stabilization: { iterations: 150 },
    },
  };
  const network = new Network(
    container, { nodes: nodesSet, edges: edgesSet } , options
  )

  network.on('selectNode', (event) => {
    const nodeId = event.nodes[0].split('/')[1]
    const nodeInternalId = network.body.data.nodes.get(event.nodes[0]).internal_id
    const search = new URLSearchParams({
      x: event.pointer.canvas.x,
      y: event.pointer.canvas.y,
    }).toString()

    const xhr = new XMLHttpRequest()
    // Todo : use the new API when clicking a node 
    xhr.open('GET', '/concepts/' + nodeId + '.json?' + search, true)
    xhr.onreadystatechange = function() {
      if(xhr.readyState === XMLHttpRequest.DONE) {
        const response = JSON.parse(this.responseText)
        nodesSet.update(response.nodes)

        const filteredResponse = response.edges.filter( (edge) => {
          return edgesSet.get({ filter: item => item.from == edge.from && item.to == edge.to }).length == 0
        })

        edgesSet.add(filteredResponse)
      }
    }
    xhr.send()

    const form = document.querySelector('#thesaurusModal form')
    if(!form.querySelector('#concept_map')) {
      form.insertAdjacentHTML(
        'beforeend',
        '<input type="hidden" name="concepts[]" id="concept_map">'
      )
    }
    form.querySelector('#concept_map').value = nodeInternalId
  })

  document.addEventListener('shown.bs.modal', (e) => {
    if (e.relatedTarget.dataset.bsTarget != "#thesaurusModal") return

    network.fit()
  })
})
import L from 'leaflet'
require('leaflet.markercluster')

class LeafletMap {

  constructor(element) {
    this._selector = element
    this.extractOptions()
    this.initMap()
    this.addMarkerHandler()
  }

  extractOptions() {
    const opts = document
      .getElementById(this._selector)
      .dataset

    this.locations = opts.locations
    this._articleIcon = L.icon({
      iconUrl: opts.articleIconUrl,
      iconSize: [38, 61],
      iconAnchor: [19, 61],
      popupAnchor: [0, -68]
    })

    this._relativeIcon = L.icon({
      iconUrl: opts.relativeIconUrl,
      iconSize: [38, 61],
      iconAnchor: [19, 61],
      popupAnchor: [0, -64]
    })
  }

  initMap() {
    this._map = L.map(
      this._selector,
      { tap: L.Browser.safari && L.Browser.mobile }
    ).setView([51.505, -0.09], 1)

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 18
    }).addTo(this._map)

    // this._control = L.control
    //   .layers([], [], { hideSingleBase: true })
    //   .addTo(this._map)

    this.addArticleLocations(this.locations,  this._articleIcon, 'Article en cours')
  }

  refreshMap() {
    setTimeout(function() { this._map.invalidateSize() }.bind(this), 500)
  }

  addArticleLocations(locations, icon, text) {
    let markers = L.markerClusterGroup()

    for (const location of JSON.parse(locations)) {
      for (const coordinates of location.coordinates){
        let marker = L.marker(
            new L.LatLng(coordinates.table.lat, coordinates.table.lon),
            { title: location.label_fr, icon: icon },
        )
        marker.bindPopup(
            '<p>' + location.text + '</p>',
            {
              'maxWidth': '500',
              'className' : 'custom'
            }
        ).on('popupopen', (event) => {
          document
              .querySelector('#' + this._selector + ' .leaflet-popup .popupLocation')
              .addEventListener('click', this.popupClickHandler)
          // console.log(event.popup.getContent())
          // console.log(event.popup.getContent().querySelector('.popupLocation'))
          // this.popupClickHandler(location.label_fr, location.lat, location.lng)
        });
        markers.addLayer(marker)
      }
    }

    if (icon == this._articleIcon) {
      markers.addTo(this._map)
    } else {
      this._control.addOverlay(markers, text)
    }
  }

  addMarkerHandler() {
    document.addEventListener(
      'map.addMarker',
      this.addRelativeMarkers,
      false
    );
  }

  setupPopupLinkEvent() {
    const links = document.querySelectorAll('#' + this._selector + ' .popupLocation')

    for (link of links) {
      link.removeEventListener('click', this.popupClickHandler, false)
      link.addEventListener('click', this.popupClickHandler, false)
    }
  }

  popupClickHandler = (event) => {
    event.preventDefault
  }

  addRelativeMarkers = (event) => {
    fetch('/articles/' + event.detail.articleId + '/locations.json',
          { headers: { "Content-Type": "application/json; charset=utf-8" } })
      .then(response => { console.log(response); return response.json() })
      .then(response => {
        this.addArticleLocations(response, this._relativeIcon, event.detail.title)
      })
  }

  urlWithCurrentLocation(location_id) {
    let url = new URL(window.location)
    let params = new URLSearchParams(url.search)
    params.append('locations[]', location_id)
    url.search = params
    return url.toString()
  }
}

export default LeafletMap

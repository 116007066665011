document.addEventListener('turbo:load', function() {
  // Get the header
  let header = document.getElementById("myheader")

  document
    .querySelector('.main-container')
    .addEventListener('scroll', e => {
      if (event.currentTarget.scrollTop > header.offsetTop) {
        header.classList.add("sticky")
      } else {
        header.classList.remove("sticky")
      }
    })
})

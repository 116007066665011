import RangeSlider from './range-slider'

class ThesaurusSelector {

    constructor(element) {
        this.parentElement = document.querySelector(element)
        this.chartElement = this.parentElement.querySelector('.concept-chart')
        this.sliderElement = this.parentElement.querySelector('.concept-slider')
        this.fromElement = this.parentElement.querySelector('#concept_from_input')
        this.toElement = this.parentElement.querySelector('#concept_to_input')
        this.initializeSlider()
    }

    initializeSlider() {
        let newRangeSlider = new RangeSlider(this.sliderElement)
        newRangeSlider.onChange = function(min, max) {
            this.fromElement.value = min
            this.toElement.value = max
        }.bind(this)

        newRangeSlider.didChanged = function(min, max) {
            this.chartElement.data.datasets[0].backgroundColor = 'orange'
            this.chartElement.update(0)
        }.bind(this)
    }
}

export default ThesaurusSelector

function trackScroll(event, button) {
  if (event.currentTarget.scrollTop > document.documentElement.clientHeight) {
    button.classList.add('back_to_top-show')
  } else {
    button.classList.remove('back_to_top-show')
  }
}

document.addEventListener('turbo:load', function() {
  const back_to_top = document.querySelector('.back_to_top')

  const element = document.querySelector('.main-container')
  element && element.addEventListener('scroll', (e) => {
    trackScroll(e, back_to_top)
  })

  back_to_top && back_to_top.addEventListener('click', function() {
    document.querySelector('.main-container').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  })
})
